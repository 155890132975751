import React from "react";
import {Sidenav} from "rsuite";
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import GitHubIcon from "@material-ui/icons/GitHub";

import "../styles/SidebarNav.css";
import "react-typist/dist/Typist.css";
import FadeInSection from "./FadeInSection";
import {Book} from "@material-ui/icons";

const isMobile = window.innerWidth < 600;

class SidebarNav extends React.Component {
    constructor() {
        super();
        this.state = {
            expanded: true,
            activeKey: "1"
        };
        this.handleSelect = this.handleSelect.bind(this);
    }

    handleSelect(eventKey) {
        this.setState({
            activeKey: eventKey
        });
    }

    render() {
        const {expanded} = this.state;

        const links = [
            <a href="/">/home</a>,
            <a href="#about">/about</a>,
            <a href="#experience">/experience</a>,
            <a href="#projects">/side-projects</a>,
            <a href="https://blog.ilkinmehdiyev.com">/blog</a>
        ];

        return (
            <div className="sidebar-nav">
                {!isMobile && (
                    <Sidenav
                        expanded={expanded}
                        defaultOpenKeys={["3", "4"]}
                        activeKey={this.state.activeKey}
                        onSelect={this.handleSelect}
                        appearance={"subtle"}
                    >
                        <Sidenav.Body>
                            <div className="sidebar-links">
                                {links.map((link, i) => (
                                    <FadeInSection delay={`${i + 1}00ms`}>
                                        <div>{link}</div>
                                    </FadeInSection>
                                ))}
                            </div>
                        </Sidenav.Body>
                    </Sidenav>
                )}
                <div className="sidebar-logos" href="/">
                    <a style={{marginRight: "1em"}} href="mailto:hey@ilkinmehdiyev.com">
                        <EmailRoundedIcon style={{fontSize: 20}}></EmailRoundedIcon>
                    </a>
                    <a style={{marginRight: "1em"}} href="https://github.com/ilkin0">
                        <GitHubIcon style={{fontSize: 19}}></GitHubIcon>
                    </a>
                    <a style={{marginRight: "1em"}} href="https://www.linkedin.com/in/ilkin-mehdiyev/">
                        <LinkedInIcon style={{fontSize: 21}}></LinkedInIcon>
                    </a>
                    <a style={{marginRight: "1em"}} href="https://blog.ilkinmehdiyev.com">
                        <Book style={{fontSize: 21}}></Book>
                    </a>

                </div>
            </div>
        );
    }
}

export default SidebarNav;
