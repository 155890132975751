import React from "react";

import "../styles/Intro.css";
import Typist from "react-typist";
import "react-typist/dist/Typist.css";
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";
import FadeInSection from "./FadeInSection";

class Intro extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: true,
            activeKey: "1",
            visible: true
        };
        this.handleSelect = this.handleSelect.bind(this);
    }

    handleSelect(eventKey) {
        this.setState({
            activeKey: eventKey
        });
    }

    render() {
        return (
            <div id="intro">
                <Typist avgTypingDelay={120}>
          <span className="intro-title">
            {"hi, "}
              <span className="intro-name">{"ilkin"}</span>
              {" here."}
          </span>
                </Typist>
                <FadeInSection>
                    <div className="intro-subtitle">I create technical stuff.</div>
                    <div className="intro-desc">
                        I'm a Software Engineer. I have intense
                        interest in Software Development, DevOps, and Linux.
                        <b> And creating new and exciting stuffs with technologies I use.</b>
                    </div>
                    <a
                        href="mailto:hey@ilkinmehdiyev.com"
                        className="intro-contact"
                    >
                        <EmailRoundedIcon></EmailRoundedIcon>
                        {"Say hello"}
                    </a>
                </FadeInSection>
            </div>
        );
    }
}

export default Intro;
