import React from "react";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FadeInSection from "./FadeInSection";

const isHorizontal = window.innerWidth < 600;

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    if (isHorizontal) {
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    } else {
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`vertical-tabpanel`}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    if (isHorizontal) {
        return {
            id: `full-width-tab-${index}`,
            "aria-controls": `full-width-tabpanel-${index}`
        };
    } else {
        return {
            id: `vertical-tab-${index}`
        };
    }
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: "theme.palette.background.paper",
        display: "flex",
        height: 300
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`
    }
}));

const JobList = () => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const experienceItems = {
        "Bally's Interactive": {
            jobTitle: "Software Engineer @",
            companyDetail: "The digital arm of one of the world's leading entertainment provider",
            companyWebsite: "https://www.ballys.com",
            duration: "November 2023 - PRESENT",
            desc: []
        },
        "DataOrb": {
            jobTitle: "Software Engineer III @",
            companyDetail: "The AI-Powered Customer Engagement Hub For Omnichannel Success",
            companyWebsite: "https://www.dataorb.ai/",
            duration: "February 2023 - November 2023",
            desc: [
                "Initiated a Semantic Search proof-of-concept with Pinecone & Python, implemented in Spring Boot.",
                "Developed core microservices using Java 8 and Spring Boot, following Domain and Test-Driven Design.",
                "Participated technical discussions, enhance team processes for efficiency, and work with cross-functional teams such as Front-End, QA, DevOps, etc. to keep up with industry trends and innovate."
            ]
        },
        "Payriff OJSC": {
            jobTitle: "Software Developer @",
            companyDetail: "A Fintech which provides Digital Payment Solutions for Internet Acquiring",
            companyWebsite: "https://payriff.com/",
            duration: "February 2022 - February 2023",
            desc: [
                "Creating RESTful services / adapters for current needs." +
                "Supporting current Monolith application while migrating it to Microservices. " +
                "Creating new Microservices based on needs.",
                "Tech Stack: Java 11, Spring Boot, Spring Data JPA, Spring Cloud( API Gateway, OpenFeign, Eureka, Config Server), " +
                "Docker, AWS (CloudWatch, Fargate, S3, RDS), etc."
            ]
        }, "Logicom Software": {
            jobTitle: "Full-Stack Java Developer @",
            companyDetail: "",
            companyWebsite: "",
            duration: "October 2021 - February 2022",
            desc: [
                "Creating new standalone RESTful APIs for current needs via Spring Boot, Spring Data JPA, MySql, Flyway, Docker, etc.",
                "Creating detailed Javadoc and Swagger documentation for the APIs.",
                "Integrating new APIs to Microservice application.",
                "Tech Stack: Java 17, Spring Boot, Flyway, Spring Data JPA, Docker, MapStruct, etc."
            ]
        },
        "Cybernet LLC": {
            jobTitle: "Software Engineer @",
            companyDetail: "One of the leading companies providing Information Technology services and solutions in Azerbaijan.",
            companyWebsite: "https://cybernet.az/",
            duration: "August 2020 - October 2021",
            desc: [
                "Created and optimized new RESTFUL APIs via Jax-RS and JPA.",
                "Acceptance and processing of mortgage applications at the national level in full electronic format.",
                "Maintained and supported existing EMS(Government Electron Mortgage System) APIs",
                "Tech Stack: Java 8, Java EE JAX-RS, Spring Data JPA, Oracle."
            ]
        },
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <Tabs
                orientation={!isHorizontal ? "vertical" : null}
                variant={isHorizontal ? "fullWidth" : "scrollable"}
                value={value}
                onChange={handleChange}
                className={classes.tabs}
            >
                {Object.keys(experienceItems).map((key, i) => (
                    <Tab label={isHorizontal ? `0${i}.` : key} {...a11yProps(i)} />
                ))}
            </Tabs>
            {Object.keys(experienceItems).map((key, i) => (
                <TabPanel value={value} index={i}>
          <span className="joblist-job-title">
            {experienceItems[key]["jobTitle"] + " "}
          </span>
                    <a href={experienceItems[key]["companyWebsite"]}><span className="joblist-job-company">{key}</span></a>
                    <div className="joblist-duration">
                        {experienceItems[key]["duration"]} <br/>
                        {experienceItems[key]["companyDetail"]}
                    </div>
                    <ul className="job-description">
                        {experienceItems[key]["desc"].map(function (descItem, i) {
                            return (
                                <FadeInSection delay={`${i + 1}00ms`}>
                                    <li key={i}>{descItem}</li>
                                </FadeInSection>
                            );
                        })}
                    </ul>
                </TabPanel>
            ))}
        </div>
    );
};

export default JobList;
