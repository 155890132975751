import React from "react";
import "../styles/Projects.css";
import FolderOpenRoundedIcon from "@material-ui/icons/FolderOpenRounded";
import GitHubIcon from "@material-ui/icons/GitHub";
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser";
import FadeInSection from "./FadeInSection";

class Projects extends React.Component {
    constructor() {
        super();
        this.state = {
            expanded: true,
            activeKey: "1"
        };
        this.handleSelect = this.handleSelect.bind(this);
    }

    handleSelect(eventKey) {
        this.setState({
            activeKey: eventKey
        });
    }

    render() {
        const projects = {
            "PARCEL DELIVERY APP": {
                desc:
                    "A simple parcel delivery app which is built on microservices",
                techStack: "Java 17, Spring cloud, Docker, PostgreSql, Gradle",
                link: "https://github.com/ilkin0/parcel_delivery_app",
            },
            "Payroll Process System": {
                desc:
                    "A simple payroll processing system with minimal features. Users can upload either CSV or TXT file to start processing",
                techStack: "Java 11, Spring Boot, Spring Data JPA, PostgreSql, Maven",
                link: "https://github.com/ilkin0/hr-web-management",
            },
            "HR Web Management system": {
                desc:
                    "A demo HR Web Management system focuses on integration to web based workflow for Human Resources. ",
                techStack: "Java 17, Spring Boot, Spring Data JPA, PostgreSql, Gradle",
                link: "https://github.com/ilkin0/payroll_processing_system",
            }
        };

        return (
            <div id="projects">
                <div className="section-header ">
                    <span className="section-title">/ side-projects</span>
                </div>

                <div className="project-container">
                    <ul className="projects-grid">
                        {Object.keys(projects).map((key, i) => (
                            <FadeInSection delay={`${i + 1}00ms`}>
                                <li className="projects-card">
                                    <div className="card-header">
                                        <div className="folder-icon">
                                            <FolderOpenRoundedIcon
                                                style={{fontSize: 35}}
                                            ></FolderOpenRoundedIcon>
                                        </div>
                                        <span className="external-links">
                      <a className="github-icon" href={projects[key]["link"]}>
                        <GitHubIcon
                            style={{
                                fontSize: 20,
                                color: "var(--lightest-slate)"
                            }}
                        ></GitHubIcon>
                      </a>
                                            {projects[key]["open"] && (
                                                <a className="open-icon" href={projects[key]["open"]}>
                                                    <OpenInBrowserIcon
                                                        style={{
                                                            fontSize: 25,
                                                            color: "var(--lightest-slate)"
                                                        }}
                                                    ></OpenInBrowserIcon>
                                                </a>
                                            )}
                    </span>
                                    </div>

                                    <div className="card-title">{key}</div>
                                    <div className="card-desc">{projects[key]["desc"]}</div>
                                    <div className="card-tech">{projects[key]["techStack"]}</div>
                                </li>
                            </FadeInSection>
                        ))}
                    </ul>
                </div>
            </div>
        );
    }
}

export default Projects;
