import React from "react";
import "../styles/About.css";
import FadeInSection from "./FadeInSection";

class About extends React.Component {
    constructor() {
        super();
        this.state = {
            expanded: true,
            activeKey: "1"
        };
        this.handleSelect = this.handleSelect.bind(this);
    }

    handleSelect(eventKey) {
        this.setState({
            activeKey: eventKey
        });
    }

    render() {
        const one = (
            <p>
                I'm into Back/Front end, Cloud development, and DevOps.

                Motivated and self-taught <b>Software Engineer</b>.
                I'm passionate about learning the latest technologies, (such as front-end, back-end, cloud, etc.),
                and implement these technologies in real life scenarios.
                I have high attention to detail, love challenges,
                and am trying to find a way to improve myself.
                I am always eager to learn and ready to work on my own goals. I enjoy finding and learning new things.
                I love <b>cats</b>, <b>manga/comics</b>, <b>video games</b>, and <b>creating</b> stuffs with the web.
                <b> Software Engineering is my passion</b>.

                {/*I'm into Back/Front end, Cloud development, and DevOps.*/}
                {/*Highly motivated and self-taught <b>Software Engineer</b>.*/}
                {/*Passionate about learning the latest technologies whether that is front-end, back-end, cloud, etc. I*/}
                {/*have high attention to detail, love challenges, and am constantly trying to find a way to improve*/}
                {/*myself. I am always eager to learn and I'm always ready to work on my own goals. I love to find and*/}
                {/*learn something new. Love <b>cats</b>, <b>manga/comics</b>, <b>gaming</b>, <b>creating</b> stuffs with*/}
                {/*the web.*/}
                {/*<b> Software Engineering is my passion</b>.*/}
            </p>
        );
        const two = (
            <p>
                I'm into <b>Back-End </b> development, <b>Cloud </b> development, and{" "}
                <b>DevOps</b>.
            </p>
        );

        const desc_items = [one, two];

        const tech_stack = [
            "Java",
            "MySQL",
            "Spring Boot",
            "FlyWay",
            "Spring Data JPA/ JDBC",
            "Liquibase",
            "MapStruct",
            "AWS",
            "Unit & Integration testing",
            "JavaScript ES6",
            "NodeJs",
            "Vue 3",
            "Gitlab CI",
            "Nuxt 3",
        ];

        // const tech_items = tech_stack.map(stack => <li>{stack}</li>);
        const image = require("./assets/photo.jfif");

        return (
            <div id="about">
                <FadeInSection>
                    <div className="section-header ">
                        <span className="section-title">/ about me</span>
                    </div>
                    <div className="about-content">
                        <div className="about-description">
                            {desc_items}
                            {"Here are my Tech Stack:"}
                            <ul className="tech-stack">
                                {tech_stack.map(function (tech_item, i) {
                                    return (
                                        <FadeInSection delay={`${i + 1}00ms`}>
                                            <li>{tech_item}</li>
                                        </FadeInSection>
                                    );
                                })}
                            </ul>
                        </div>
                        <div className="about-image">
                            <img src={image} alt={image}/>
                        </div>
                    </div>
                </FadeInSection>
            </div>
        );
    }
}

export default About;
